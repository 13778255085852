import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  WrapperLight,
  WrapperLightGreen,
  Content,
} from "../components/section-styling"
import { ButtonLink } from "../components/ButtonLink"
import styled from "styled-components"
import Accordion from "../components/Accordion"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogContent = styled(Content)`
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 1.45rem 1.08rem;

  p {
    margin-bottom: 0.8rem;
  }

  a {
    color: var(--black);
    text-decoration: none;
  }

  .center {
    text-align: center;
    margin-bottom: 0;
  }

  .text {
    margin-bottom: 0px;
  }
`

export default function BlogPage({ data }) {
  return (
    <Layout>
      <Seo title="Blog" />

      <WrapperLightGreen>
        <Content>
          <div>
            <h1>We have stories to share.</h1>
          </div>

          <StaticImage
            className="large-image"
            src="../images/sassy-plant-13.png"
            width={250}
            layout="fixed"
            alt="A small branch with flower buds."
            data-testid="blog-image"
          />
        </Content>
      </WrapperLightGreen>

      <WrapperLight>
        <BlogContent>
          <h3>Posts:</h3>
          {data.allWpPost.nodes.map(node => (
            <div key={node.slug}>
              <Accordion
                title={parse(node.title)}
                date={node.date}
                text={parse(node.excerpt)}
                testid={node.slug}
              />
              <ButtonLink to={"/blog/" + node.slug} className="light listItem">
                READ MORE
              </ButtonLink>
            </div>
          ))}
        </BlogContent>
      </WrapperLight>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        excerpt
        slug
      }
    }
  }
`
